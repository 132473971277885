<template>
	<case-details :caseStates="'view_detail'"></case-details>
</template>
<script>
import CaseDetails from '@/views/businessComponents/CaseDetails'
export default {
	components: {
		CaseDetails
	}
}
</script>
